import React from "react"
import CaseStudyHeaderSection from "./components/CaseStudyHeaderSection"
import CaseStudyDescriptionSection from "./components/CaseStudyDescriptionSection"
import CaseStudyWorkSection from "./components/CaseStudyWorkSection"
import NewsletterSection from "../newsletter/NewsletterSection"

const CaseStudyMindValleyTemplate = ({ image }) => {
  return (
    <React.Fragment>
      <CaseStudyHeaderSection
        title="Mindvalley"
        description="The leading transformational education company in the world turns to Hook Points to expand and grow"
        image={image}
      />
      <CaseStudyDescriptionSection
        list={
          <ul>
            <li>
              Helped train their internal social media and communications
              department around effective use of Hook Points and storytelling to
              drive{" "}
              <span>
                massive growth in their online audience, reach and revenue
              </span>
            </li>
            <li>
              <span>Restructured their entire approach</span> to online
              education with regards to how they interact with the leading
              experts they bring in to educate their marketplace
            </li>
            <li>
              <span>Increased video views</span> across the MindValley platform
              to 50 million + views in a few short months while expanding the
              position and presence of the company and its content to an engaged
              global audience.
            </li>
            <li>
              By leveraging Hook Points, we took their CEO’s Facebook page from
              500,000 followers to <span>2.3 million</span> in few months
            </li>
          </ul>
        }
      />
      <CaseStudyWorkSection
        next={"/case-study-crank"}
        subText="Get started here"
        text="Let us tell you more about what we did with MindValley. "
      />
      <NewsletterSection />
    </React.Fragment>
  )
}

export default CaseStudyMindValleyTemplate
